import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as states from "../../redux/states";
import {
  fetchCmsContentIndexScroll,
  selectCmsContentIndexByType,
  selectCmsContentIndexStateByType,
} from "../../redux/slices/cmsTypeContentIndexSlice";
import { selectIsAuthorized } from "../../redux/slices/userSlice";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ReleaseNotesScrollPage from "./releaseNotesScrollPage";
import RevHeader from "../../components/header";

const ReleaseNotesSelectionManager = ({ dispatcher }) => {
  const cmsType = "releasenotes";

  const tempstate = useSelector((state) => {
    return selectCmsContentIndexStateByType(state, cmsType);
  });

  const [state, setState] = useState(tempstate);
  const index = useSelector((state) => {
    return selectCmsContentIndexByType(state, cmsType);
  });

  useEffect(() => {
    if (state !== tempstate) {
      setState(tempstate);
    }
  }, [state, tempstate]);

  // hier moet je de categoryList aanpassen

  useEffect(() => {
    if (state === states.idle) {
      dispatcher(
        fetchCmsContentIndexScroll({
          cmsType: cmsType,
          continueFromCurrentRecord: false,
        })
      );
    } else if (state === states.searchChanged) {
      const timer = setTimeout(() => {
        dispatcher(
          fetchCmsContentIndexScroll({
            cmsType: cmsType,
            continueFromCurrentRecord: false,
          })
        ).then(() => {
          return () => clearTimeout(timer);
        });
      }, 600);
    }
  }, [dispatcher, index, state]);

  // add infinity loading page functions
  const loadMore = () => {
    dispatcher(
      fetchCmsContentIndexScroll({
        cmsType: cmsType,
        continueFromCurrentRecord: state !== states.searchChanged,
      })
    );
  };

  const isAuthorized = useSelector(selectIsAuthorized);
  const loading = state === states.loading;
  const hasNextPage = state !== states.completed;
  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMore,
    error: state === states.failed,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <>
      <RevHeader title={"Release notes"} />
      {index && (
        <ReleaseNotesScrollPage
          sentryRef={sentryRef}
          loading={loading}
          hasNextPage={hasNextPage}
          articles={index}
          isAuthorized={isAuthorized}
        />
      )}
    </>
  );
};

export default ReleaseNotesSelectionManager;
