import RevImageSelector from "./RevImageSelector";

export const CompositeHeader = ({
  title,
  subTitle,
  image,
  subSubTitle,
  content,
  isAuthorized,
  images,
  isCenter,
}) => {
  return (
    <>
      {title && (
        <h1
          className={`title is-size-4-mobile is-3 has-text-weight-bold${
            isCenter ? " has-text-centered" : ""
          }`}
        >
          {title}
        </h1>
      )}
      {subTitle && (
        <h2
          className={`subtitle is-size-5-mobile is-4 is-flex-touch has-text-weight-bold${
            isCenter ? " has-text-centered" : ""
          }`}
        >
          {subTitle}
        </h2>
      )}
      {subSubTitle && (
        <h2
          className={`subtitle is-size-5-mobile is-4 is-flex-touch${
            isCenter ? " has-text-centered" : ""
          }`}
        >
          {subSubTitle}
        </h2>
      )}
      {content && <div className="is-size-7-mobile is-3 pb-5">{content}</div>}
      {image && !image.isThumbnail && (
        <RevImageSelector images={images} isAuthorized={isAuthorized} />
      )}
    </>
  );
};

const RevHeader = ({
  title,
  subTitle,
  image,
  subSubTitle,
  content,
  extraFiles,
  isAuthorized,
  isCenter,
}) => {
  let images = [image];
  if (extraFiles) {
    images = [image, ...extraFiles?.filter((x) => x.isImage)];
  }

  return (
    <section className="hero is-small">
      {/* .hero-body */}
      <header className="hero-body" style={{ alignItems: "start" }}>
        <div className="container">
          <CompositeHeader
            title={title}
            subTitle={subTitle}
            subSubTitle={subSubTitle}
            image={image}
            content={content}
            isAuthorized={isAuthorized}
            images={images}
            isCenter={isCenter}
          />
        </div>
      </header>
    </section>
  );
};

export default RevHeader;
