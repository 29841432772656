import React from "react";
import RevHeader from "../../components/header";
import ComponentInjector from "./components/ComponentInjector";
import { DangerousDiv } from "../../components/dangerousGerneric";
import Helaas from "../../components/aboNodig";
function Article({ article }) {
  return (
    <>
      <RevHeader {...article} />
      <section className="hero is-small">
        <div className="hero-body pt-0" style={{ alignItems: "start" }}>
          <ComponentInjector component={article.path}>
            <div className="container">
              <div className="columns is-vcentered">
                <div className="column is-12">
                  {article.contentRequiresAccount && <Helaas />}
                  {!article.contentRequiresAccount && (
                    <DangerousDiv
                      content={
                        article.contents
                          ? article.contents[0]?.content
                          : undefined
                      }
                      className="content"
                    />
                  )}
                </div>
              </div>
            </div>
          </ComponentInjector>
        </div>
      </section>
    </>
  );
}

export default Article;

// https://github.com/BulmaTemplates/bulma-templates/blob/master/templates/cards.html
