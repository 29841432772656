import { useDispatch, useSelector } from "react-redux";
import { CompositeHeader } from "../../../components/header";
import * as pages from "../../../navigation";
import * as projFields from "../projectConstants";
import QuickStartNavigationButtons from "./quickStartNavigationButtons";
import {
  selectPublicAddressData,
  updatePublicData,
} from "../../../redux/slices/quickStartSlice";
import {
  selectBeheerObjecten,
  selectObjectSoorten,
} from "../../../redux/slices/vtwSlice";
import { SelectInputRow } from "../../../components/common/SelectInput";
import { useState } from "react";
import { RoofTypesForBeheerObjecten } from "../../../modules/revCalculation";
import { RevImgFull } from "../../../components/common/RevImg";
import {
  DisplayLabel,
  DisplayTextArea,
} from "../../../components/common/DisplayLabel";
import { NumberInput } from "../../../components/common/NumberInput";

const QuickStartSelectBuildingType = () => {
  const publicData = useSelector(selectPublicAddressData);

  const objectSoorten = useSelector(selectObjectSoorten);
  const [projectParams, setProjectParams] = useState(publicData);
  const dispatcher = useDispatch();

  let objectTypes = [];
  if (projectParams?.objectSoort) {
    objectTypes = projectParams.objectSoort.objectTypen;
  }

  const beheerObjecten = useSelector(selectBeheerObjecten);
  const roofTypes = RoofTypesForBeheerObjecten(beheerObjecten);

  const updateProjectParams = (e) => {
    let tempProjectParams = { ...projectParams };
    const { name } = e.target;
    let value = Number(e.target.value);

    if (name === "objectSoort") {
      tempProjectParams.objectType = undefined;
      tempProjectParams.roofType = undefined;
      value = objectSoorten.find((x) => x.id === value);
    }

    if (name === "objectType") {
      tempProjectParams.roofType = undefined;
      value = objectTypes.find((x) => x.id === value);
    }

    if (name === "roofType") {
      value = roofTypes.find((x) => x.id === value);
    }
    tempProjectParams[name] = value;
    setProjectParams(tempProjectParams);
    dispatcher(updatePublicData({ publicData: tempProjectParams }));
  };

  const bagData = projectParams.bagData;
  const epData = projectParams.epData;
  const thirdPartyText = `${
    bagData && bagData.go && Number(bagData.go) !== 0
      ? `${bagData.go} (bron: ${bagData.source})`
      : ""
  } ${
    epData && epData.go && Number(epData.go) !== 0
      ? `${epData.go} (bron: ${epData.source})`
      : ""
  }`.trim();

  return (
    <>
      <CompositeHeader title="Pas eigenschappen van het gebouw aan" />
      <SelectInputRow
        name="objectSoort"
        label={"Gebouw soort"}
        onChange={updateProjectParams}
        placeholder={"maak een keuze"}
        value={projectParams.objectSoort?.id}
        options={objectSoorten}
      />
      {projectParams.objectSoort && (
        <SelectInputRow
          name={"objectType"}
          onChange={updateProjectParams}
          label="Gebouw type"
          value={projectParams.objectType?.id}
          placeholder="maak een keuze"
          options={objectTypes}
        />
      )}
      {projectParams.objectType && (
        <SelectInputRow
          name={"roofType"}
          onChange={updateProjectParams}
          label="Kies daktype"
          value={projectParams.roofType?.id}
          options={roofTypes}
        />
      )}
      <DisplayTextArea
        label="Adres"
        text={`${bagData?.street} ${projectParams.houseNumber} ${projectParams.postalCode}, ${bagData?.city}`}
      />
      {thirdPartyText.length > 0 && (
        <DisplayTextArea
          label="Openbare GO"
          simpleTooltipText={
            "Gebruiksoppervlakte vanuit het BAG-register opgehaald. Het GO vanuit BAG kan afwijken vergeleken met het referentiegebouw. Dit kan door bijvoorbeeld een latere uitbouw."
          }
          text={thirdPartyText}
        />
      )}
      <NumberInput
        label="Wijzig GO"
        name={projFields.go}
        value={projectParams.go}
        onChange={updateProjectParams}
        simpleTooltipText={
          "Hier kunt u, indien gewenst, het Gebruiksoppervlakte nog wijzigen."
        }
      />
      <NumberInput
        label="Wijzig bouwjaar"
        name={projFields.bouwjaar}
        value={projectParams.bouwjaar}
        onChange={updateProjectParams}
      />
      {projectParams.epData && (
        <DisplayLabel
          label={"Energielabel"}
          text={epData?.energyLabel}
          addonText={`bron: ${epData?.source}`}
          className={"has-background-light"}
        />
      )}
      {projectParams.bagData && (
        <DisplayLabel
          label={"Functie"}
          text={bagData?.type}
          addonText={`bron: ${bagData?.source}`}
          className={"has-background-light"}
        />
      )}
      <RevImgFull src={projectParams.mapImage?.url} />
      &nbsp;
      <RevImgFull src={projectParams.buildingImage?.url} />
      &nbsp;
      <RevImgFull src={projectParams.sateliteImage?.url} />
      {/* Dit is om te zorgen dat de mobiele knoppen niet over de plaatjes hangen */}
      <div className="is-hidden-tablet" style={{ height: "90px" }}></div>
      <QuickStartNavigationButtons
        path={pages.quickStartSelectionList}
        isActive={true}
        showChoose={false}
        bagUrl={publicData?.bagData?.bagUrl}
      />
    </>
  );
};

export default QuickStartSelectBuildingType;
