import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Layout from "./components/layoutMain";
import Home from "./pages/home/home";
import Zonnepaneel from "./pages/sustainabilityProjects/Zonnepaneel";
import NotFound from "./pages/NotFound";
import CmsContentSearchPage from "./pages/cms/CmsContentSearchPage";
import CmsContentIndexPage from "./pages/cms/CmsContentIndexPage";
import NewProjectPageManager from "./pages/project/newProject/NewProjectPageManager";
import SigninOidc from "./oidc/signin-oidc";
import SignoutOidc from "./oidc/signout-oidc";
import CmsRedirect from "./pages/cmsRedirect";
import * as pages from "./navigation";
import "./css/rev-viewport-dependent-sizing.scss";
import "./css/rev-cssonly-toggle.scss";
import "./css/rev-cssonly-tabs.scss";
import "bulma/sass/utilities/_all.sass";
import "bulma/css/bulma.min.css";
import "bulma-carousel/dist/css/bulma-carousel.min.css";
import { CookieConsentQuestion } from "./components/cookieComponents";
import "./css/bulma-overrides.scss";
import "./css/custom.scss";
import "./css/rev-modal.scss";
import "./css/Tabs.css";
import "./css/loader.scss";
import "./css/rev-nav-menu.scss";
import "./css/rev-custom-component.scss";
import "./css/rev-project.scss";
import "./css/rev-tooltip.css";
import "./css/rev-transitions.css";
import "./css/rev-text.scss";
import "./css/rev-side.scss";
import "./css/rev-table-overrides.css";
import "./css/rev-color-overrides.scss";
import "./css/rev-cssonly-toggle.scss";

import RefreshCache from "./pages/cms/refreshCache";
import { addEventListersAndMobileSupport } from "./custom-scripts/ui-scripts-after-load.js";
import { AnimatePresence } from "framer-motion";
import ProjectRapportageManager from "./pages/project/ProjectRapportageManager";
import ProjectPlanManager from "./pages/project/ProjectPlanManager";
import ProjectKenmerkenManager from "./pages/project/ProjectKenmerkenManager";
import ProjectOnderhoudskostenManager from "./pages/project/ProjectOnderhoudskostenManager";
import LessonSelectionManager from "./pages/lesson/LessonSelectionManager";
import GeneralCmsPageManager from "./pages/general/generalCmsPageManager";
import ProfilePageManager from "./pages/profile/profilePageManager";
import RedirectSignin from "./oidc/redirect-signin";

import RequireAuth from "./oidc/requireAuth";
import RevHelmetManager from "./components/RevHelmetManager";
import {
  IntitializeAnalytics,
  VisualAnalytics,
} from "./components/AnalyticsManager";
import ProjectComponentWrapper from "./pages/project/ProjectComponentWrapper";
import CmsComponentWrapper from "./pages/CmsComponentWrapper";
import HybridHeatPump from "./pages/sustainabilityProjects/HybridHeatPump";
import QuickStartSelectAddress from "./pages/project/quickStart/selectAddress";
import QuickStartSelectBuildingType from "./pages/project/quickStart/selectBuildingType";
import QuickStartWrapper from "./pages/project/quickStart/quickStartWrapper";
import SelectBeheerObject from "./pages/project/quickStart/selectBeheerObject";
import FinishQuickStart from "./pages/project/quickStart/finishQuickStart";
import KengetallenSelectionManager from "./pages/kengetallen/kengetallenSelectionManager";
import UserProjectsManager from "./pages/project/userProjects/UserProjectsManager";
import ComplexPlanManager from "./pages/project/userProjects/ComplexPlanManager";
import ReleaseNotesSelectionManager from "./pages/releaseNotes/releaseNotesSelectionManager";
import { useDispatch } from "react-redux";
import { fetchCmsContentIndexScroll } from "./redux/slices/cmsTypeContentIndexSlice";

function App() {
  addEventListersAndMobileSupport();
  var dispatcher = useDispatch();
  dispatcher(fetchCmsContentIndexScroll({ cmsType: "releasenotes" }));
  IntitializeAnalytics();
  const isProd = process.env.REACT_APP_ENVIRONMENT === "PRD";
  const RevRouter = () => {
    const location = useLocation();
    return (
      <>
        <VisualAnalytics />
        <RevHelmetManager />
        <Layout>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              <Route
                path={pages.quickStartAddress}
                element={
                  <QuickStartWrapper>
                    <QuickStartSelectAddress />
                  </QuickStartWrapper>
                }
              />
              <Route
                path={pages.quickStartBuildingType}
                element={
                  <QuickStartWrapper>
                    <QuickStartSelectBuildingType />
                  </QuickStartWrapper>
                }
              />

              <Route
                path={pages.quickStartSelectionList}
                element={
                  <QuickStartWrapper>
                    <SelectBeheerObject />
                  </QuickStartWrapper>
                }
              />

              <Route
                path={pages.quickStartDone}
                element={
                  <QuickStartWrapper>
                    <FinishQuickStart />
                  </QuickStartWrapper>
                }
              />
              <Route exact path={pages.home} element={<Home />} />
              <Route
                path={pages.zonnepaneelcalculatie}
                element={<Zonnepaneel />}
              />
              <Route
                path={pages.warmtepompcalculatie}
                element={<HybridHeatPump />}
              />
              <Route path={pages.user} element={<ProfilePageManager />} />

              {!isProd && <Route path="/refresh" element={<RefreshCache />} />}

              {pages.cmsIndexSearch.map((val) => {
                return (
                  <Route key={val}>
                    <Route
                      path={`/${val}`}
                      element={
                        <CmsComponentWrapper>
                          <CmsContentIndexPage />
                        </CmsComponentWrapper>
                      }
                      key={val}
                    ></Route>

                    <Route
                      path={`/${val}/:primary/:secondary/:tertiary/:quaternary`}
                      element={
                        <CmsComponentWrapper>
                          <CmsContentSearchPage />
                        </CmsComponentWrapper>
                      }
                    />
                    <Route
                      path={`/${val}/:primary/:secondary/:tertiary`}
                      element={
                        <CmsComponentWrapper>
                          <CmsContentSearchPage />
                        </CmsComponentWrapper>
                      }
                    />
                    <Route
                      path={`/${val}/:primary/:secondary`}
                      element={
                        <CmsComponentWrapper>
                          <CmsContentSearchPage />
                        </CmsComponentWrapper>
                      }
                    />
                    <Route
                      path={`/${val}/:primary`}
                      element={
                        <CmsComponentWrapper>
                          <CmsContentSearchPage />
                        </CmsComponentWrapper>
                      }
                    />
                  </Route>
                );
              })}
              {/* <Route path={`/index/:cmsType`} element={<CmsContentIndexPage />} /> */}

              <Route
                path={`${pages.releaseNotes}`}
                element={
                  <CmsComponentWrapper>
                    <ReleaseNotesSelectionManager />
                  </CmsComponentWrapper>
                }
              />

              <Route
                path={`${pages.lesson}/:primary/:secondary/:tertiary/:quaternary`}
                element={
                  <CmsComponentWrapper>
                    <LessonSelectionManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={`${pages.lesson}/:primary/:secondary/:tertiary`}
                element={
                  <CmsComponentWrapper>
                    <LessonSelectionManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={`${pages.lesson}/:primary/:secondary`}
                element={
                  <CmsComponentWrapper>
                    <LessonSelectionManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={`${pages.lesson}/:primary`}
                element={
                  <CmsComponentWrapper>
                    <LessonSelectionManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={`${pages.lesson}`}
                element={
                  <CmsComponentWrapper>
                    <LessonSelectionManager />
                  </CmsComponentWrapper>
                }
              />

              <Route
                path={`${pages.kengetallen}`}
                element={
                  <CmsComponentWrapper>
                    <KengetallenSelectionManager />
                  </CmsComponentWrapper>
                }
              />

              <Route
                path={`${pages.kengetallen}/:primary/:secondary/:tertiary/:quaternary`}
                element={
                  <CmsComponentWrapper>
                    <CmsContentSearchPage />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={`${pages.kengetallen}/:primary/:secondary/:tertiary`}
                element={
                  <CmsComponentWrapper>
                    <CmsContentSearchPage />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={`${pages.kengetallen}/:primary/:secondary`}
                element={
                  <CmsComponentWrapper>
                    <CmsContentSearchPage />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={`${pages.kengetallen}/:primary`}
                element={
                  <CmsComponentWrapper>
                    <KengetallenSelectionManager />
                  </CmsComponentWrapper>
                }
              />

              <Route
                path={`${pages.newproject}/:id${pages.projectKenmerken}`}
                element={
                  <ProjectComponentWrapper>
                    <ProjectKenmerkenManager />
                  </ProjectComponentWrapper>
                }
              />
              <Route
                path={`${pages.newproject}/:id${pages.projectOnderhoudskosten}`}
                element={
                  <ProjectComponentWrapper>
                    <ProjectOnderhoudskostenManager />
                  </ProjectComponentWrapper>
                }
              />
              {/* project routes */}
              <Route
                path={`${pages.newproject}/:id${pages.projectRapportage}`}
                element={
                  <ProjectComponentWrapper>
                    <ProjectRapportageManager />
                  </ProjectComponentWrapper>
                }
              />

              <Route
                path={`${pages.newproject}/:id${pages.projectPlan}`}
                element={
                  <ProjectComponentWrapper>
                    <ProjectPlanManager />
                  </ProjectComponentWrapper>
                }
              />
              <Route
                path={`${pages.newproject}/:id`}
                element={
                  <ProjectComponentWrapper>
                    <ProjectKenmerkenManager />
                  </ProjectComponentWrapper>
                }
              />

              <Route
                path={pages.newproject}
                element={<NewProjectPageManager />}
              />
              <Route
                path={`${pages.projectPage}/:id${pages.projectKenmerken}`}
                element={
                  <RequireAuth>
                    <ProjectComponentWrapper>
                      <ProjectKenmerkenManager />
                    </ProjectComponentWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path={`${pages.projectPage}/:id${pages.projectOnderhoudskosten}`}
                element={
                  <RequireAuth>
                    <ProjectComponentWrapper>
                      <ProjectOnderhoudskostenManager />
                    </ProjectComponentWrapper>
                  </RequireAuth>
                }
              />
              {/* project routes */}
              <Route
                path={`${pages.projectPage}/:id${pages.projectRapportage}`}
                element={
                  <RequireAuth>
                    <ProjectComponentWrapper>
                      <ProjectRapportageManager />
                    </ProjectComponentWrapper>
                  </RequireAuth>
                }
              />

              <Route
                path={`${pages.projectPage}/:id${pages.projectPlan}`}
                element={
                  <RequireAuth>
                    <ProjectComponentWrapper>
                      <ProjectPlanManager />
                    </ProjectComponentWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path={`${pages.projectPage}/:id`}
                element={
                  <RequireAuth>
                    <ProjectComponentWrapper>
                      <ProjectKenmerkenManager />
                    </ProjectComponentWrapper>
                  </RequireAuth>
                }
              />

              <Route
                path={pages.userProjectsPage}
                element={<UserProjectsManager />}
              />

              <Route
                path={pages.userComplexMjopPage}
                element={
                  <RequireAuth>
                    <ComplexPlanManager />
                  </RequireAuth>
                }
              />
              <Route path={`r/:route`} element={<CmsRedirect />} />
              <Route path={pages.redirectSignIn} element={<RedirectSignin />} />
              <Route path={pages.signIn} element={<SigninOidc />} />
              <Route path={pages.signOut} element={<SignoutOidc />} />
              <Route
                path={pages.handigeLinks}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.cursussen}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.overRev}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.vtwBeleggingsvastgoed}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.vtwMaatschappelijksvastgoed}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.vtwBouwgebreken}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.vtwOnderhoudskostenkompas}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.support}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.realEstateValuator}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.webservices}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.events}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.toelichtingOnderhoud}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.disclaimer}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />
              <Route
                path={pages.cookies}
                element={
                  <CmsComponentWrapper>
                    <GeneralCmsPageManager />
                  </CmsComponentWrapper>
                }
              />

              <Route path={pages.notfound} element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AnimatePresence>
        </Layout>
        <CookieConsentQuestion />
      </>
    );
  };

  return (
    <>
      <VisualAnalytics />
      <Router>
        <RevRouter />
      </Router>
    </>
  );
}

export default App;
