import { RevImgFull } from "../../../components/common/RevImg";
import * as classes from "../../../custom-scripts/custom-javascript-classes";
import { RadioButtonGroup } from "../../../components/common/RadioButtonGroup";
import { RevRoundNumber } from "../../../modules/revCalculation";
import Loader from "../../../components/loader";
import { SubmitAndCancelButton } from "../../../components/common/FormButton";
import NewProjectNavigationButton from "./NewProjectNavigationButton";
import { useSwipeable } from "react-swipeable";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { RevTooltipSimple } from "../../../components/common/revTooltip";
const NewProjectModal = ({
  beheerObject,
  objectIndex,
  createProject,
  toggleModal,
  dataTarget,
  exploitationData,
  navigateLeft,
  navigateRight,
  exploitationDataLoading,
  highlightClass,
}) => {
  const config = {
    onSwipedLeft: () => navigateRight(objectIndex),
    onSwipedRight: () => navigateLeft(objectIndex),
    onSwipedUp: () => toggleModal(),
    onSwipedDown: () => toggleModal(),
  };

  const handlers = useSwipeable({
    // for logging
    ...config,
  });

  return (
    <div {...handlers}>
      <NewProjectNavigationButton
        objectIndex={objectIndex}
        navigateLeft={navigateLeft}
        navigateRight={navigateRight}
      />
      <section className="is-small pb-1">
        <div className="container">
          <h1
            className={`has-text-weight-bold has-text-centered rev-title-newproject ${highlightClass}`}
          >
            {beheerObject.displayVtwCode === undefined
              ? beheerObject.vtwCode
              : beheerObject.displayVtwCode}{" "}
            {beheerObject.objectSoort}
          </h1>
        </div>
      </section>

      <form onSubmit={createProject} {...handlers}>
        {/* <SubmitButtonPrimary className="is-rounded" text="Maak nieuw project" /> */}
        <section className="section pt-0">
          <div className="container">
            <div className="columns is-vcentered is-desktop p-2">
              <div className="column is-one-third">
                {beheerObject.image && (
                  <div className="has-text-centered">
                    <LazyLoadComponent visibleByDefault={false}>
                      <RevImgFull
                        className={`${classes.imageLimiter} rev-image-semiround`}
                        src={beheerObject.image.src}
                        alt={beheerObject.image.altText}
                      />
                    </LazyLoadComponent>
                  </div>
                )}
              </div>
              <div className="column">
                <h2 className="has-text-weight-semibold	is-size-5 is-size-6-mobile rev-subtitle-newproject mb-1">{`${beheerObject.description}`}</h2>
                <p className="is-size-7-mobile">
                  {beheerObject.longDescription}
                </p>
                <br />
                <p className="is-desktop-only">
                  <strong>Fotowijzer code:</strong>{" "}
                  {beheerObject.fotoWijzerCode}
                </p>
              </div>
            </div>
            {exploitationDataLoading && <Loader center={true} />}
            {!exploitationDataLoading && exploitationData && (
              <>
                <div className="content">
                  <table className="table is-striped is-narrow">
                    <thead>
                      <tr className="has-background-primary">
                        <th className="has-text-white-bis">
                          Onderhoudskosten/ woning/ jaar
                        </th>
                        <th className="has-text-white-bis has-text-right">
                          Uitponding&nbsp;
                          <RevTooltipSimple
                            label={"i"}
                            tooltipText={"<25 jaar"}
                          />
                        </th>
                        <th className="has-text-white-bis has-text-right">
                          Doorexploitatie&nbsp;
                          <RevTooltipSimple
                            label={"i"}
                            tooltipText={">25 jaar"}
                          />
                        </th>
                      </tr>
                    </thead>
                    {exploitationData.bouwdeelKosten && (
                      <tbody>
                        {exploitationData.bouwdeelKosten.map((bouwdeel, i) => {
                          return (
                            <tr key={i}>
                              <td>{bouwdeel.name}</td>
                              <td className="has-text-right">
                                €&nbsp;
                                {bouwdeel.uitponding}
                              </td>
                              <td className="has-text-right">
                                €&nbsp;{bouwdeel.exploitatie}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>

                  <table className="table is-narrow rev-table-newproject is-striped">
                    <thead>
                      <tr className="has-background-primary has-text-white-bis">
                        <th className="has-text-white-bis">
                          {exploitationData.herbouwKostenHeader}
                        </th>

                        {exploitationData.herbouwKosten &&
                          exploitationData.herbouwKosten[0].laag !== 0 && (
                            <th className="has-text-white-bis has-text-right">
                              Laag
                            </th>
                          )}

                        <th className="has-text-white-bis has-text-right">
                          Basis
                        </th>

                        {exploitationData.herbouwKosten &&
                          exploitationData.herbouwKosten[0].hoog !== 0 && (
                            <th className="has-text-white-bis has-text-right">
                              Hoog
                            </th>
                          )}
                      </tr>
                    </thead>
                    {exploitationData.herbouwKosten && (
                      <tbody>
                        {exploitationData.herbouwKosten.map((kosten, i) => {
                          return (
                            <tr key={i}>
                              <td>{kosten.name}</td>
                              {kosten.laag !== 0 && (
                                <td className="has-text-right">
                                  €&nbsp;{RevRoundNumber(kosten.laag, true)}
                                </td>
                              )}
                              <td className="has-text-right">
                                €&nbsp;{RevRoundNumber(kosten.basis, true)}
                              </td>
                              {kosten.hoog !== 0 && (
                                <td className="has-text-right">
                                  €&nbsp;{RevRoundNumber(kosten.hoog, true)}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>

                  <span className="tag p-2 is-warning">
                    {exploitationData.vatDescription}
                  </span>
                  <div className="control">
                    <label className="checkbox rev-button-size">
                      <input type="checkbox" name="includeVat" />
                      &nbsp; BTW meerekenen in project
                    </label>
                  </div>
                  {beheerObject.isWoning && (
                    <RadioButtonGroup
                      buttons={[
                        {
                          text: "Commercieel",
                          value: true,
                          checked: beheerObject.isCommercialProperty,
                        },
                        {
                          text: "Particulier",
                          value: false,
                          checked: !beheerObject.isCommercialProperty,
                        },
                      ]}
                      name="commercialProperty"
                    />
                  )}
                  {!beheerObject.isWoning && (
                    <input
                      type="hidden"
                      name="commercialProperty"
                      value={true}
                    />
                  )}
                  <input
                    type="hidden"
                    name="vtwCode"
                    value={beheerObject.vtwCode}
                  />
                  <input type="hidden" name="cycle" value={25} />
                  {/* <SubmitButtonPrimary
                    className="is-rounded"
                    text="Maak nieuw project"
                  /> */}
                  <div style={{ padding: "30px" }}></div>
                </div>
              </>
            )}
          </div>
        </section>
        <SubmitAndCancelButton
          text="Kies object"
          cancelText="Ga terug"
          dataTarget={dataTarget}
          opaqueBackground={true}
          onClick={toggleModal}
        />
      </form>
    </div>
  );
};

export default NewProjectModal;
