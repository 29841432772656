import { useSelector } from "react-redux";
import { selectLatestReleaseNotesPublishDate } from "../../redux/slices/cmsTypeContentIndexSlice";
import {
  FooterRevLinkInternal,
  RevPageLink,
} from "../../components/common/Buttons";
import * as pages from "../../navigation";
import {
  differenceInDays,
  formatDateWithLocale,
} from "../../modules/dateTimeFormats";

const ReleasNotes = () => {
  const latestPublishDate = useSelector(selectLatestReleaseNotesPublishDate);
  const today = new Date();
  const daysSinceRelease = differenceInDays(latestPublishDate, today);

  return (
    <>
      {daysSinceRelease < 10 && (
        <>
          <section className="section p-0 has-background-primary">
            <div className="container has-text-centered-touch">
              <RevPageLink
                text={`Nieuwe release: ${formatDateWithLocale(
                  latestPublishDate
                )}`}
                path={pages.releaseNotes}
                className="has-text-white"
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ReleasNotes;
