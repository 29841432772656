import ReactDOM from "react-dom/client";
import { useEffect, useRef } from "react";
import * as components from "./InjectableCmsComponents";

// Input string with spaces

// Function to convert into camel Case
function cleanComponentName(str) {
  // Using replace method with regEx
  return str.replace(/\s+/g, "").replace(/\W/g, "").toLowerCase();
}

const ComponentInjector = (props) => {
  // https://www.w3schools.com/react/react_useref.asp
  let root = useRef(undefined);
  useEffect(() => {
    if (props.component) {
      const componentName = cleanComponentName(props.component);
      const rootElement = document.querySelector(`[id="${componentName}" i]`);
      if (rootElement) {
        if (!root.current) {
          root.current = ReactDOM.createRoot(rootElement);
        }

        switch (componentName) {
          default:
          case "ozbenriool":
            root.current.render(<components.OzbEnRiool />);
            break;
          case "waterschapslasten":
            root.current.render(<components.Waterschaplasten />);
            break;
        }
      }
    }
  });

  return <>{props.children}</>;
};

export default ComponentInjector;
