import { TextInputSimple } from "../../../components/common/TextInput";
import { SelectInput } from "../../../components/common/SelectInput";

const NewProjectSelection = ({
  isSelection,
  onChange,
  label,
  value,
  placeholder,
  options,
  className,
  ...props
}) => (
  <>
    {isSelection && (
      <SelectInput
        label={label}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        className={className}
        options={options}
      />
    )}
    {!isSelection && (
      <TextInputSimple
        label={label}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={className}
      />
    )}
  </>
);

export default NewProjectSelection;
