import { useEffect, useState } from "react";
import { client } from "../../../api/client";
import { SelectInput } from "../../../components/common/SelectInput";
import Loader from "../../../components/loader";

export const OzbEnRiool = () => {
  const [ozbData, setOzbData] = useState();

  const openClass = "rev-toggle-open";
  const closeClass = "rev-toggle-close";

  const [year, setYear] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const path = `api/cmscontent/GetOzbEnRiool${
        year !== undefined ? `/${year}` : ""
      }`;

      await client
        .get(path)
        .then((d) => {
          setOzbData(d.data);
          setLoading(false);
        })
        .catch((e) => console.error(e));
    };
    fetchData();
  }, [year]);

  const onYearSelection = (e) => {
    setYear(Number(e.target.value));
  };

  // javascript function added to maintain
  const toggleBox = (e) => {
    const row = document.getElementById(`${e?.target?.dataset?.target}`);
    if (row) {
      row.classList.toggle(openClass);
      row.classList.toggle(closeClass);
    }
  };

  const width = "70px";

  const TH = (props) => {
    return (
      <td
        style={{
          maxWidth: width,
        }}
      >
        {props.children}
      </td>
    );
  };

  return (
    <>
      {loading && <Loader center={true} />}
      {!loading && ozbData && (
        <>
          <SelectInput
            name="select"
            onChange={onYearSelection}
            value={ozbData.year}
            options={ozbData.yearSelection}
          />
          <br />
        </>
      )}

      <div className="table-container" style={{ width: "fitContent" }}>
        <table className="rev-css-toggle-box" style={{ overflow: "hidden" }}>
          {!loading &&
            ozbData &&
            ozbData.provincies.map((d) => {
              return (
                <tbody key={d.name}>
                  <tr
                    className={closeClass}
                    id={`row-${d.name}`}
                    onClick={toggleBox}
                    data-target={`row-${d.name}`}
                  >
                    <td
                      colSpan={ozbData.rates.length + 2}
                      data-target={`row-${d.name}`}
                    >
                      <label data-target={`row-${d.name}`}>{d.name}</label>
                    </td>
                  </tr>

                  <tr key={`${d.name}_headers`} className="rev-toggle-header">
                    <TH />
                    {ozbData &&
                      ozbData.rates.map((x) => (
                        <TH key={x.id}>
                          <div title={x.name} style={{}}>
                            {x.name}
                          </div>
                        </TH>
                      ))}
                  </tr>

                  {d.gemeentes.map((g, i) => {
                    return (
                      <tr
                        key={`${d.name}_${g.name}`}
                        className="rev-toggle-row"
                      >
                        <td>{g.name}</td>
                        {g.rates.map((r) => (
                          <td
                            className="has-text-right"
                            key={`${d.name}_${g.name}_${r.id}`}
                            width={width}
                            style={{
                              maxWidth: width,
                              fontSize: "16px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            <div title={r.name}>{r.rate}</div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              );
            })}
        </table>
      </div>
    </>
  );
};

export const Waterschaplasten = () => {
  const [waterAuthorityData, setWaterAuthorityData] = useState();
  const [year, setYear] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const path = `api/cmscontent/GetWaterschapKosten${
        year !== undefined ? `/${year}` : ""
      }`;

      await client
        .get(path)
        .then((d) => {
          setWaterAuthorityData(d.data);
          setLoading(false);
        })
        .catch((e) => console.error(e));
    };
    fetchData();
  }, [year]);

  const onYearSelection = (e) => {
    setYear(Number(e.target.value));
  };

  return (
    <>
      {loading && <Loader center={true} />}
      {!loading && waterAuthorityData && (
        <>
          <SelectInput
            name="select"
            label=""
            onChange={onYearSelection}
            value={waterAuthorityData.year}
            options={waterAuthorityData.yearSelection}
          />
          <br />
        </>
      )}

      <div className="table-container" style={{ width: "fitContent" }}>
        <table style={{ overflow: "hidden" }}>
          <thead>
            <tr>
              <th>Nr</th>
              <th>Waterschap</th>
              <th className="has-text-right">
                Heffing gebouw eigenaar in % van de WOZ-waarde
              </th>
              <th className="has-text-right">
                Ingezetene gebruiker in € per huishouden
              </th>
              <th className="has-text-right">
                Ongebouwd eigenaar in € per hectare
              </th>
              <th className="has-text-right">
                Zuiveringsheffing gebruiker basistarief in € per eenheid
              </th>
            </tr>
          </thead>
          {!loading && waterAuthorityData && (
            <tbody className="everyother-row-blue">
              {waterAuthorityData.rates.map((rates) => {
                return (
                  <tr key={rates.name}>
                    <td>{rates.order}</td>
                    <td>{rates.name}</td>
                    <td className="has-text-right">{rates.rate}</td>
                    <td className="has-text-right">{rates.userRate}</td>
                    <td className="has-text-right">{rates.areaRate}</td>
                    <td className="has-text-right">{rates.unitRate}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};
