import { Link } from "react-router-dom";
import * as pages from "../../navigation";
import { useEffect } from "react";

const ProjectNavigation = ({
  id,
  highlight,
  showReporting,
  showPlan,
  userIsAuthorized,
}) => {
  const projectType = userIsAuthorized ? pages.projectPage : pages.newproject;

  useEffect(() => {
    var navbar = document.querySelector('[aria-label="main navigation"]');
    if (navbar) {
      navbar.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 1)";
    }
  }, []);

  return (
    <section
      id="rev-project-menu"
      className="section is-small has-background-white is-fixed-top"
      style={{
        paddingTop: "1em",
        paddingBottom: "1em",
        position: "sticky",
        zIndex: "29",
        overflow: "hidden",
        top: "96px",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 8px 4px -5px",
      }}
    >
      <div className="buttons is-centered">
        <div id="rev-kenmerken-btn">
          <Link
            className={`button rev-project-button has-text-weight-bold rev-highlight-kenmerken ${
              highlight === pages.projectKenmerken
                ? "has-text-white-bis is-active"
                : "is-primary is-outlined"
            }`}
            to={`${projectType}/${id}${pages.projectKenmerken}`}
          >
            Kenmerken
          </Link>
        </div>
        <div id="rev-onderhoudkosten-btn">
          <Link
            className={`button rev-project-button has-text-weight-bold rev-highlight-onderhoudskosten ${
              highlight === pages.projectOnderhoudskosten
                ? "has-text-white-bis is-active"
                : "is-primary is-outlined"
            }`}
            to={`${projectType}/${id}${pages.projectOnderhoudskosten}`}
          >
            Exploitatiekosten
          </Link>
        </div>
        {showPlan && (
          <div id="rev-mjop-btn">
            <Link
              className={`button rev-project-button has-text-weight-bold rev-highlight-mjop ${
                highlight === pages.projectPlan
                  ? "has-text-white-bis is-active"
                  : "is-primary is-outlined"
              }`}
              to={`${projectType}/${id}${pages.projectPlan}`}
            >
              MJOP
            </Link>
          </div>
        )}
        {showReporting && (
          <div id="rev-rapportage-btn">
            <Link
              className={`button rev-project-button has-text-weight-bold rev-highlight-rapportage ${
                highlight === pages.projectRapportage
                  ? "has-text-white-bis is-active"
                  : "is-primary is-outlined"
              }`}
              to={`${projectType}/${id}${pages.projectRapportage}`}
            >
              Rapport
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProjectNavigation;
