import { DangerousDiv } from "../../components/dangerousGerneric";
import Loader from "../../components/loader";

const ReleaseNotesScrollPage = ({
  articles,
  sentryRef,
  hasNextPage,
  loading,
}) => {
  const show = articles.length > 0;
  console.log(articles);
  const ReleaseNote = ({ article }) => {
    return (
      <section className="section p-2">
        <div className="container">
          <p className="content has-text-weight-bold">
            {article.title}
            <span className="has-text-weight-normal">
              {" "}
              <DangerousDiv content={article.metaDescription} />
            </span>
          </p>
        </div>
      </section>
    );
  };

  const Release = ({ article }) => {
    return (
      <div className="container">
        <p className="title is-4 is-size-4-mobile pt-5">
          {article.mainCategory}
        </p>
        <p className="subtitle is-6">
          <span className="tag is-primary">{article.publishedOn}</span>
        </p>
      </div>
    );
  };

  let previousRelease = undefined;
  let showHeader = true;
  return (
    <>
      {show && (
        <section className="section pt-0">
          <div className="container">
            {articles.map((article, i) => {
              if (previousRelease !== article.primaryFilter) {
                previousRelease = article.primaryFilter;
                showHeader = true;
              } else {
                showHeader = false;
              }
              return (
                <div className="container" key={i}>
                  {showHeader && <Release article={article} />}
                  <ReleaseNote article={article} />
                </div>
              );
            })}

            {(loading || hasNextPage) && (
              <div ref={sentryRef}>
                <Loader />
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default ReleaseNotesScrollPage;
