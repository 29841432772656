import * as states from "../states";
import store from "../store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  error: null,
  recordsPerRequest: 20,
  filters: {},
  filterState: states.idle,
  contentIndex: {
    wikivastgoed: {
      status: states.idle,
      filter: "",
      categories: [],
      currentRecord: 0,
      articles: [],
    },
    kennisbank: {
      status: states.idle,
      filter: "",
      categories: [],
      currentRecord: 0,
      articles: [],
    },
    bouwgebreken: {
      status: states.idle,
      filter: "",
      categories: [],
      currentRecord: 0,
      articles: [],
    },
    all: {
      status: states.idle,
      filter: "",
      categories: [],
      currentRecord: 0,
      articles: [],
    },
    nieuws: {
      status: states.idle,
      filter: "",
      categories: [],
      currentRecord: 0,
      articles: [],
    },
    kengetallen: {
      status: states.idle,
      filter: "",
      categories: [],
      currentRecord: 0,
      articles: [],
    },
    general: {
      status: states.idle,
      filter: "",
      categories: [],
      currentRecord: 0,
      articles: [],
    },
    releasenotes: {
      status: states.idle,
      filter: "",
      categories: [],
      currentRecord: 0,
      articles: [],
    },
  },
};

export const fetchCmsContentIndexScroll = createAsyncThunk(
  "cms/CmsContentIndexScroll",
  async ({ cmsType, filter, categories, continueFromCurrentRecord }) => {
    const indexContent = store.getState().cmsTypeIndex.contentIndex[cmsType];
    const request = {
      cmsType,
      filter,
      startIndex: continueFromCurrentRecord ? indexContent.currentRecord : 0,
      records: 20,
      categories: categories,
    };
    const response = await client.post(
      `/api/cmscontent/CmsContentIndexScroll`,
      request
    );
    return JSON.stringify(response.data);
  }
);

export const fetchCmsContentFilters = createAsyncThunk(
  "cms/fetchCmsContentFilters",
  async () => {
    const response = await client.get(`/api/cmscontent/GetCmsFilters`);
    return JSON.stringify(response.data);
  }
);

const cmsTypeContentIndexSlice = createSlice({
  name: "cmsTypeIndex",
  initialState,
  reducers: {
    clearCmsSearch: (state, action) => {
      const { cmsType } = { ...action.payload };
      state.contentIndex[cmsType].articles = [];
      state.contentIndex[cmsType].currentRecord = 0;
    },
    resetTypeContent: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCmsContentIndexScroll.fulfilled, (state, action) => {
        const response = JSON.parse(action.payload);
        let currentIndex = state.contentIndex[action.meta.arg.cmsType];
        if (!action.meta.arg.continueFromCurrentRecord) {
          // this is a new search, replace the old
          currentIndex.articles = {};
          currentIndex.filter = action.meta.arg.filter;
          if (action.meta.arg.categories) {
            currentIndex.categories = action.meta.arg.categories;
          }
        }
        currentIndex.articles = Object.assign(
          {},
          currentIndex.articles,
          response.articles
        );

        currentIndex.currentRecord = response.lastRecordIndex;
        state.contentIndex[action.meta.arg.cmsType].status = response.isFinished
          ? states.completed
          : states.partial;
      })
      .addCase(fetchCmsContentIndexScroll.pending, (state, action) => {
        state.contentIndex[action.meta.arg.cmsType].status = states.loading;
      })
      .addCase(fetchCmsContentIndexScroll.rejected, (state, action) => {
        state.contentIndex[action.meta.arg.cmsType].status = states.failed;
        console.log(action);
        state.error = action.error.message;
      })
      .addCase(fetchCmsContentFilters.pending, (state) => {
        state.filterState = states.loading;
      })
      .addCase(fetchCmsContentFilters.rejected, (state, action) => {
        state.filterState = states.failed;
        state.error = action.error.message;
      })
      .addCase(fetchCmsContentFilters.fulfilled, (state, action) => {
        const categories = JSON.parse(action.payload);
        console.log(categories);
        Object.keys(categories).forEach((cmsType) => {
          state.contentIndex[cmsType].categories = categories[cmsType];
        });
        state.filterState = states.completed;
      });
  },
});

export const { clearCmsSearch, resetTypeContent } =
  cmsTypeContentIndexSlice.actions;

export default cmsTypeContentIndexSlice.reducer;

export const selectCmsContentIndexByType = (state, cmsType) => {
  const cmsData = state.cmsTypeIndex.contentIndex[cmsType];
  if (cmsData && cmsData.articles) {
    return Object.values(cmsData.articles);
  }
  return [];
};

export const selectLatestReleaseNotesPublishDate = (state) => {
  if (state.cmsTypeIndex.contentIndex.releasenotes?.articles) {
    var articles = Object.values(
      state.cmsTypeIndex.contentIndex.releasenotes.articles
    );
    if (articles.length > 0) return new Date(articles[0].publishDate);
  }

  return new Date(0);
};

export const selectCmsContentIndexStateByType = (state, cmsType) =>
  state.cmsTypeIndex.contentIndex[cmsType]?.status;

export const selectCmsContentIndexFilterByType = (state, cmsType) =>
  state.cmsTypeIndex.contentIndex[cmsType]?.filter;

export const selectCmsContentCategoriesByType = (state, cmsType) =>
  state.cmsTypeIndex.contentIndex[cmsType]?.categories;

export const selectCmsContentCategoriesState = (state) =>
  state.cmsTypeIndex.filterState;
